var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "card-header" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "form-group col-12 text-right" },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "singleBit", params: { id: "new" } } } },
              [
                _c("BaseButton", { attrs: { type: "green", gradient: "" } }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("addNewItem", { item: _vm.$tc("bit") })) +
                      " "
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "card-body" },
      [
        _c("BaseListTable", {
          attrs: {
            items: _vm.items,
            "header-fields": _vm.headerFields,
            type: "bits",
          },
          on: { delete: _vm.remove },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }